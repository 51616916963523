import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import { Config } from './config'

document.title = environment.brandTitle

if (environment.production) {
	enableProdMode()
}

if (environment.brand == Config.BR_RDS) {
	document.body.classList.add('rds')
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err))

Config.load(environment)
