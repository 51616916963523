<div class="row d-flex justify-content-center">
	<div class="col-md-6 col-xl-5 col-xxl-3 col-12 position-relative p-xs-0">
		<fa-icon [icon]="faChevronLeft" faChevronLeft [hidden]="!session.showLogin" (click)="session.showLogin = false"></fa-icon>
		<div class="modal-container text-center shadow d-flex align-items-center">
			<div style="width: 100%">
				<img class="logo" src="assets/images/logo.png" alt="Visionix" />

				<div class="modal-container__register" *ngIf="!session.showLogin">
					<div>
						<h3 class="modal-container__title my-5 text-uppercase">{{ "QRCODE.FIRST.MODAL_TITLE" | translate }}</h3>
					</div>
					<div>
						<p>{{ "QRCODE.FIRST.MODAL_BODY" | translate }}</p>
					</div>
					<div class="my-4">
						<button type="button" class="btn btn-danger" (click)="gotoCreateUser()">{{ "QRCODE.FIRST.MODAL_BUTTON" | translate }}</button>
						<!-- routerLink="createuser"  -->
						<!-- <a href="/createuser" type="button" class="btn btn-danger"> Registrati </a> -->
					</div>

					<!-- divider -->
					<div *ngIf="isBrandVisionix">
						<div class="modal-container__divider row">
							<div class="divider position-relative col"></div>

							<div class="col">
								<p class="text-uppercase">{{ "QRCODE.OR" | translate }}</p>
							</div>

							<div class="divider position-relative col"></div>
						</div>

						<div class="my-4">
							<p>{{ "QRCODE.SECOND.MODAL_BODY" | translate }}</p>
						</div>

						<div class="my-4">
							<button type="button" class="btn btn-secondary" (click)="session.showLogin  = !session.showLogin ">{{ "QRCODE.SECOND.MODAL_BUTTON" | translate }}</button>
						</div>
					</div>
				</div>

				<div class="modal-container__login" *ngIf="session.showLogin">
					<loginform></loginform>
				</div>
			</div>
		</div>
	</div>
</div>
<span class="version-number position-absolute bottom-0 start-0 ps-2">{{ ConfigName }}</span>
